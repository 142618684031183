<template>
  <!-- 企业管理 -->
  <div>
    <!-- 顶部新增按钮 -->
    <div class="onBtn">
      <el-button type="danger" size="mini" @click="addNewProfile" style="margin-left: auto">新增</el-button>
    </div>
    <!-- 中间表格区 -->
    <div>
      <el-table :data="data" border height="600">
        <el-table-column label="序号" prop="id" align="center"></el-table-column>
        <el-table-column label="appId" prop="appId" align="center"></el-table-column>
        <el-table-column label="公司名称" prop="name" align="center"></el-table-column>
        <el-table-column label="对应通证" prop="tokenNum" align="center"></el-table-column>
        <el-table-column label="归集地址" prop="power" align="center"></el-table-column>
        <!-- <el-table-column label="通证数量" align="center" width="150">
          <template slot-scope="scope">
            <el-button @click="onToken(scope.row)" type="warning" size="mini">{{ scope.row.token || 0 }}</el-button>
          </template>
        </el-table-column> -->
        <el-table-column label="公钥" width="160" align="center" prop="secret">
          <template slot-scope="scope">
            <span>{{ scope.row.secret }}</span>
            <el-button size="mini" type="primary" @click="onKeys(scope.row)">{{ scope.row.secret ? '修改公钥' : '设置公钥' }}</el-button>
            <!-- <el-button
							size="mini"
							v-else
							type="primary"
							@click="onKeys(scope.row)"
							>生成公钥</el-button
						> -->
          </template>
        </el-table-column>
        <el-table-column label="手续费" prop="serverChange" align="center"></el-table-column>
        <el-table-column label="归集地址" prop="walletArea" align="center"></el-table-column>
        <el-table-column label="通知地址" prop="notifyUrl" align="center"></el-table-column>
        <el-table-column label="所属行业" prop="trade" align="center"></el-table-column>
        <el-table-column label="注册资本" prop="registeredFund" align="center"></el-table-column>
        <el-table-column label="公司地址" prop="address" align="center" width="200"></el-table-column>
        <el-table-column label="公司网站" prop="website" align="center"></el-table-column>
        <el-table-column label="公司邮箱" prop="email" align="center"></el-table-column>
        <el-table-column label="人员规模" prop="staffSize" align="center"></el-table-column>
        <!-- <el-table-column label="公众号" prop="officialAccounts" align="center"></el-table-column> -->
        <el-table-column label="保证金" prop="earnestMoney" align="center">
          <template v-slot="{row}">
            <div>
              <div>{{row.earnestMoney}}</div>
              <div class="div" @click="addbz(row,2)">增加</div>
              <div class="div" @click="addbz(row,1)">减少</div>
            </div>
          </template>
        </el-table-column>
        
        <el-table-column label="主营业务" align="center">
          <template slot-scope="scope">
            <div @click="businessS(scope.row.business)" class="div">详情</div>
          </template>
        </el-table-column>
        <el-table-column label="公司介绍" align="center">
          <template slot-scope="scope">
            <div @click="introduces(scope.row.introduce)" class="div">详情</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <div class="config">
              <div @click="modifyThe(scope.row)">修改</div>
              <div @click="toView(scope.row.id)">查看</div>

              <div @click="deletes(scope.$index, scope.row.id)">删除</div>
              <div @click="changeCost(scope.row)">修改企业提现手续费</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 点击弹窗 -->
    <el-dialog title="主营业务" center :visible.sync="business">
      <div>{{ businessDetails }}</div>
    </el-dialog>
    <el-dialog title="公司介绍" center :visible.sync="introduce">
      <div>{{ introduceDetails }}</div>
    </el-dialog>
    <el-dialog title="修改手续费" :visible.sync="changemen" width="30%" center :before-close="closeTrue">
      <span>
        <el-input v-model="newcost" type="number" placeholder="请输入手续费"></el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTrue()">取 消</el-button>
        <el-button type="primary" @click="trueCost()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: null,
      currentPage: 1,
      pageNumber: 20,
      data: null,
      business: false, // 主营业务
      businessDetails: null,
      introduce: false, // 公司介绍
      introduceDetails: null,
      rowInfo: '',
      changemen: false,
      newcost: ''
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    // 修改通证数量
    onToken(row) {
      let { id } = row
      this.$prompt('请输入通证数量', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d+$/,
        inputErrorMessage: '格式不正确,请重新输入'
      })
        .then(({ value }) => {
          this.axios
            .get('/admin/firm/updateNum', {
              tokenNumber: value,
              id
            })
            .then(res => {
              console.log(res)
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.mesg
                })
                this.getInfo()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.mesg
                })
              }
            })
        })
        .catch(() => {})
    },

    // 生成密钥
    onKeys(row) {
      let { id } = row
      this.$prompt('请输入公钥', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.+$/,
        inputErrorMessage: '请输入公钥'
      })
        .then(({ value }) => {
          this.axios
            .get('/admin/firm/secret', {
              id,
              str: value
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.mesg)
                this.getInfo()
              } else {
                this.$message.error(res.data.mesg)
              }
            })
        })
        .catch(() => {
          // this.$message({
          // 	type: 'info',
          // 	message: '取消输入'
          // })
        })
    },

    // 获取所有企业信息
    getInfo() {
      this.axios
        .get('/admin/firm/findAll', {
          currentPage: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          console.log(res, '6666666666')
          if (res.data.code == 200) {
            this.total = res.data.data.total
            this.currentPage = res.data.data.current
            this.data = res.data.data.records
          }
        })
    },

    // 查看公司介绍
    introduces(option) {
      this.introduce = true
      this.introduceDetails = option
    },
    // 查看公司主营业务
    businessS(option) {
      ;(this.business = true), (this.businessDetails = option)
    },

    // 点击跳转查看页面
    toView(id) {
      this.$router.push({ path: 'checkManagement', query: { id } })
    },
    // 修改手续费
    changeCost(row) {
      console.log(row)
      this.rowInfo = row
      this.changemen = true
      // this.newcost = Number(this.rowInfo.serverChange)
      console.log(this.newcost)
    },
    trueCost() {
      console.log(this.rowInfo)
      console.log(this.newcost)
      console.log(Number(this.newcost))
      if (this.newcost < 0 || this.newcost == '') {
        this.$message.error('请输入大于0的数字!')
      } else {
        console.log('11111111111')
        this.axios.get('/admin/firm/updateServerChange', { id: this.rowInfo.id, serverChange: this.newcost }).then(res => {
          console.log(res)
          this.rowInfo = ''
          this.newcost = ''
          this.changemen = false
          this.getInfo()
          this.$message({
            message: '修改成功!',
            type: 'success'
          })
        })
      }
      // if (Number(this.newcost) >= 0) {
      //   if (this.newcost == '') {
      //     this.newcost = 0
      //   }
      //   this.axios.get('/admin/firm/updateServerChange', { id: this.rowInfo.id, serverChange: this.newcost }).then(res => {
      //     console.log(res)
      //     this.rowInfo = ''
      //     this.newcost = ''
      //     this.changemen = false
      //     this.getInfo()
      //     this.$message({
      //       message: '修改成功!',
      //       type: 'success'
      //     })
      //   })
      // } else {
      //   this.$message.error('请输入大于0的数字!')
      // }
    },
    addbz(row,e){
       this.$prompt('请输入数量', '保证金', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.axios.get(`/admin/firm/earnestMoney`, {
            amount:value,
            id:row.id,
            type:e
          }).then(res=>{
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: '成功!'
              })
            }else{
              this.$message({
                type: 'error',
                message: '失败!'
              })
            }
          })
          
        }).catch(() => {
              
        });
    },
    closeTrue() {
      this.rowInfo = ''
      this.newcost = ''

      this.changemen = false
    },
    //   点击跳转修改页面
    modifyThe(row) {
      console.log(row)
      this.$router.push({
        path: 'updateManager',
        query: { item: row }
      })
    },
    // 跳转新增页面
    addNewProfile() {
      console.log('新增')
      this.$router.push('newEnterprise')
    },

    //   删除
    deletes(index, id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.axios.delete(`/admin/firm/delFirm?ids=${id}`, {}).then(res => {
            if (res.data.code == 200) {
              // 删除元素
              this.data.splice(index, 1)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              this.$message({
                type: 'errer',
                message: '删除失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageNumber = val
      this.axios
        .get('/admin/firm/findAll', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          // console.log(res.data.data);
          this.data = res.data.data.records
        })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.axios
        .get('/admin/firm/findAll', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          // console.log(res.data.data);
          this.data = res.data.data.records
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.div {
  font-size: 10px;
  color: #409eff;
}
.div:hover {
  cursor: pointer;
}
.config {
  //   display: flex;
  //   justify-content: space-around;
  div {
    display: inline;
    font-size: 10px;
    color: #409eff;
    margin-left: 20px;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
